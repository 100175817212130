.navigation {
    /*text-align: center;*/
    background-color: #F5F5F5; /* Off-White background */
    color: #333333; /* Charcoal Black text */
}

.navigation-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.navigation-header {
    background-color: #003366; /* Deep Midnight Blue */
    /*min-height: 100vh;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: left;*/
    /*font-size: calc(10px + 2vmin);*/
    /*color: white; /* Keep the text color white for contrast */
}

.navigation-link {
    color: whitesmoke; /* This could be changed to Vibrant Teal #007D8C for consistency with your new theme */
}

@keyframes navigation-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Additional styles based on the new color scheme */

/* Example for using the accent color */
.navigation-button {
    background-color: #F9A825; /* Golden Saffron for buttons or interactive elements */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.navigation-button:hover {
    background-color: #FF6B6B; /* Soft Coral for hover state to draw attention */
}

/* Example for neutral backgrounds or elements */
.navigation-section {
    background-color: #DDCBA4; /* Warm Sand for neutral sections */
    padding: 20px;
    margin: 20px 0;
}

/* Use Slate Gray for borders or less prominent text */
.navigation-line {
    border-top: 1px solid #708090; /* Slate Gray */
}

.navigation-header .navbar-brand {
    display: flex;       /* Establishes this element as a flex container */
    align-items: center; /* Centers the items vertically within the container */
    gap: 8px;            /* Optional: Adds space between the logo and the text */
}
