.tarot-card {
  margin-top: 15px;
  width: 100%;
  padding-top: 150%;
  position: relative;
}

.card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* change this to 100% to cover the full height */
  object-fit: cover;
}

.card-front {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* change this to 100% to cover the full height */
  object-fit: cover;
}

.card-name {
  position: absolute; /* absolute positioning to overlay over the image */
  bottom: 0; /* set to bottom to appear at the bottom of the card */
  left: 0;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8); /* optional: semi-transparent background for better readability */
}

@media (min-width: 576px) {
  .tarot-card {
    width: 8rem; /* fixed width on larger screens */
    padding-top: 12rem; /* fixed height on larger screens */
  }

  .tarot-card-overlay {
    width: 8rem; /* fixed width on larger screens */
    padding-top: 12rem; /* fixed height on larger screens */
    position: relative; /* or absolute, depending on your layout */
    align-items: center;
    z-index: 10000; /* make sure this is higher than the overlay's z-index */
  }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

.tarot-font-roboto {
  font-family: 'Roboto Condensed', sans-serif; /* Replace 'Roboto Condensed' with the desired font name */
  /* Other styling properties for the div */
}

@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.tarot-font-oswald {
  font-family: 'Oswald', sans-serif;
  /* Other styling properties for the div */
}

@import url('https://fonts.googleapis.com/css2?family=Exo&display=swap');

.tarot-font-exo {
  font-family: 'Exo', sans-serif;
  /* Other styling properties for the div */
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.tarot-font-montserrat {
  font-family: 'Montserrat', sans-serif;
  /* Other styling properties for the div */
}
