.App {
  /*text-align: center;*/
  background-color: #F5F5F5; /* Off-White background */
  color: #333333; /* Charcoal Black text */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #003366; /* Deep Midnight Blue */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; /* Keep the text color white for contrast */
}

.App-link {
  color: #61dafb; /* This could be changed to Vibrant Teal #007D8C for consistency with your new theme */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Additional styles based on the new color scheme */

/* Example for using the accent color */
.Accent-button {
  background-color: #F9A825; /* Golden Saffron for buttons or interactive elements */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.Accent-button:hover {
  background-color: #FF6B6B; /* Soft Coral for hover state to draw attention */
}

/* Example for neutral backgrounds or elements */
.Neutral-section {
  background-color: #DDCBA4; /* Warm Sand for neutral sections */
  padding: 20px;
  margin: 20px 0;
}

/* Use Slate Gray for borders or less prominent text */
.Border-line {
  border-top: 1px solid #708090; /* Slate Gray */
}

/* Global paragraph styles */
p {
  color: #333333; /* // Charcoal Black, already defined for .App */
  background-color: #F5F5F5;  /* Off-White, also already defined for .App */
}

/* Global link styles */
a {
  color: #61dafb;  /* Original color */
  /* If you want to use the new Vibrant Teal color you mentioned:
  color: #007D8C;
  */
}
