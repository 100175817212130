/* Lander.css */
.bottom-image {
    position: absolute;  /* Default to absolute positioning for larger screens */
    bottom: 0;
    left: -70%;
    transform: translateX(-50%);
    transform: translateY(60%);
    z-index: 0;
}

@media (max-width: 576px) { /* Adjusts for screens smaller than 576px */
    .bottom-image {
        display: none; /* Hide the image on small screens */
    }
}

@keyframes fall {
    0% {
        transform: translateY(-100vh); /* Start from above the view */
    }
    100% {
        transform: translateY(100vh); /* End at the bottom of the view */
    }
}

.img-falling {
    position: absolute;
    animation: fall 10s infinite linear; /* Adjust time as needed */
}
