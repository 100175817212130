.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  color: white;
}

@media (max-width: 576px) {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
    padding-top: 25px;
    overflow-y: auto;
  }

  .tarot-card-overlay {
    width: 8rem; /* fixed width on larger screens */
    padding-top: 12rem; /* fixed height on larger screens */
    z-index: 10000; /* make sure this is higher than the overlay's z-index */
    align-items: center;
    justify-content: center;
  }
}

.card-text-scroll {
  overflow: auto;
  max-height: 400px; /* Adjust the height as needed */
}
